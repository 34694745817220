/* eslint-disable react/display-name */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import { CustomPageRender } from '../../core/render-connector'
import fetcher from '../../core/fetcher'
import { getBlocksServerData } from '../tools/joker'
import { isPotentiallyLowEndDevice } from '../tools/infos'
import { TemplateProvider } from '../templateProvider'
import ServerPage from '../../core/components/ServerPage'
import { serviceUnavailable, notAuthorized } from '../errorPageRedirects'
import { IPage } from '@bees-web/core/types'
import { UserInfos } from '../interfaces'
import { BareFetcher } from 'swr'

const NfaTemplateRender: CustomPageRender = {
  Render: (props) => <ServerPage {...props} />,
  getServerSideProps: async (context, pageProps) => {
    const { req } = context
    const {
      query,
      locale,
      accountId: selectedPocAccount,
      storeId,
      name: pageName,
      configurations: options = {},
    } = pageProps

    const coreQs = {
      ...query,
      locale,
      ...(selectedPocAccount ? { selectedPocAccount } : {}),
      ...(storeId ? { storeId } : {}),
    }

    const {
      processDeferredOnServer = false,
      cachePage = false,
      noAuthentication = true,
    } = options

    const [language, country] = locale.split('_')

    // TODO: remove this in the future
    if (
      !noAuthentication &&
      !context.req.headers.cookie?.includes('connect.sid')
    ) {
      return notAuthorized(country, language, context?.req?.url)
    }

    const customFetch = fetcher<IPage | UserInfos>(context)

    const templatePromise = new TemplateProvider(
      customFetch as BareFetcher<IPage>
    ).getTemplate(pageName, coreQs, cachePage as boolean)
    const userInfosPromise = !noAuthentication
      ? customFetch('api/user-infos')
      : Promise.resolve({})

    const [templateSettled, userInfosSettled] = await Promise.allSettled([
      templatePromise,
      userInfosPromise,
    ])

    let template: IPage = null
    if (templateSettled.status === 'rejected') {
      return notAuthorized(country, language, context?.req?.url)
    } else {
      template = templateSettled.value
    }

    if (userInfosSettled.status === 'rejected')
      console.error('Failed to fetch user infos', userInfosSettled)

    if (!template) {
      return serviceUnavailable
    }

    const shouldProcessBlocks =
      typeof processDeferredOnServer === 'string' &&
      processDeferredOnServer.toLowerCase() === 'auto'
        ? isPotentiallyLowEndDevice(req.headers['user-agent'])
        : processDeferredOnServer

    const cachedBlocks = shouldProcessBlocks
      ? await getBlocksServerData(template, context, coreQs, {
          state: false,
          globals: false,
        })
      : {}

    return {
      props: {
        ...pageProps,
        template: template,
        userInfos:
          userInfosSettled.status === 'fulfilled' ? userInfosSettled.value : {},
        swrCache: cachedBlocks,
      },
    }
  },
}

export default NfaTemplateRender
